body {
  margin: 0 auto;
  overflow-x: hidden;
}

.width-max {
  max-width: 1600px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

.page-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 8px;
}
