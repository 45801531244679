@use "@angular/material" as mat;
@use "palette";
@include mat.elevation-classes();
@include mat.app-background();

// Define light theme.
$light-primary: mat.m2-define-palette(palette.$primary-color);
$light-accent: mat.m2-define-palette(palette.$accent-color, 500, 900, A100);
$light-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);
$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
      warn: $light-warn,
    ),
    typography: mat.m2-define-typography-config(),
  )
);
// Apply the light theme by default
@include mat.all-component-themes($light-theme);

// Define dark theme.
$dark-primary: mat.m2-define-palette(mat.$m2-blue-grey-palette, A200);
$dark-accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);
$dark-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);
$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
    typography: mat.m2-define-typography-config(),
  )
);

.dark-theme {
  @include mat.all-component-colors($dark-theme);
}

.mat-toolbar-spacer {
  flex: 1 1 auto;
}

.mat-toolbar {
  background-color: transparent;
}

.mat-mdc-card-header {
  padding: 0.75rem 1rem 0!important;
}

.mat-mdc-card-content {
  padding: 0 1rem 1rem!important;
}

.mat-mdc-card-title, .mat-mdc-card-subtitle {
  margin-bottom: 0.5rem!important;
}

.mat-mdc-card-title, .mat-mdc-card-subtitle {
  &:not(:first-child) {
    margin-top: -0.5rem;
  }
}

.mat-mdc-cell.mat-column-select,
.mat-mdc-header-cell.mat-column-select {
  overflow: visible;
}


.mat-column-end {
  display: flex;
  justify-content: flex-end;
}

.mat-content {
  align-items: center;
}

// ---------------------
.material-icons.mat-14 {
  font-size: 14px;
  width: 14px;
  height: 14px;
}

.material-icons.mat-18 {
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.material-icons.mat-24 {
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.material-icons.mat-36 {
  font-size: 36px;
  width: 36px;
  height: 36px;
}

.material-icons.mat-48 {
  font-size: 48px;
  width: 48px;
  height: 48px;
}

.material-icons.mat-56 {
  font-size: 56px;
  width: 56px;
  height: 56px;
}

.material-icons.mat-72 {
  font-size: 72px;
  width: 72px;
  height: 72px;
}

/* Drag and drop */
.cdk-drag-preview {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: white;

  .mat-mdc-cell {
    display: flex;
    flex: 1 1 0;
    border-bottom-style: none;
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-mdc-table.cdk-drop-list-dragging .mat-mdc-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-cell-small {
  flex: 60px;
  max-width: 60px;
}

.mat-expandable-row {
  min-height: 0;
  overflow: hidden;
}

.mat-expandable-row-margin-checkbox {
  margin: 16px 0 16px 60px;
}

.mat-expandable-row-margin {
  margin: 16px 0 16px 0px;
}
